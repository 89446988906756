import React from "react";

import Item from "./Item";

const Items = ({ allItems }) => {
  return (
    <>
      {allItems.map((currentValue, i) => (
        <li key={i} className={i === 0 ? "shareableItem shareableItemItemMarginTop" : "shareableItem"}>
          <Item value={currentValue} index={i} />
        </li>
      ))}
    </>
  );
};

export default Items;
