import React from "react";

const Item = ({ value, index }) => {
  const prefix = () => {
    const num = index + 1;
    if (num === 1) {
      return "1st ";
    } else if (num === 2) {
      return "2nd ";
    } else if (num === 3) {
      return "3rd ";
    } else {
      return num + "th ";
    }
  };

  return (
    <>
      <label htmlFor={"index" + index.toString()} className="shareableLabel">
        {prefix() + "Item Price:"}
      </label>
      <div className="shareableItemAmount">
        <p className="shareableDollarSign">${value}</p>
      </div>
    </>
  );
};

export default Item;
