import React, { useState } from "react";

import Item from "./Item";

const Items = ({ onItemValueChanged, sendArrayOfItems }) => {
  const [items, setItems] = useState([0]);

  const calculateTotal = (arrayOfItems) => {
    //calculateTotal
    let newTotal = 0;
    arrayOfItems.map((item) => {
      let itemNum = Number(item);
      newTotal += itemNum;
      return;
    });
    onItemValueChanged(newTotal);
    sendArrayOfItems(arrayOfItems); //This was added for link creation to share receipt
  };

  const handleClick = (e) => {
    let newItems = [...items];

    newItems.push(0);
    setItems(newItems);
  };

  const handleDelete = (index) => {
    let newItemsArray = [...items];
    newItemsArray.splice(index, 1);

    calculateTotal(newItemsArray);
    setItems(newItemsArray);
  };

  const handleValueChange = (index, newValue) => {
    let clonedItems = [...items];

    clonedItems[index] = newValue;

    calculateTotal(clonedItems);
    setItems(clonedItems);
  };

  return (
    <>
      {items.map((currentValue, i, arr) => (
        <li key={i} className={i === 0 ? "item itemMarginTop" : "item"}>
          <Item
            value={currentValue}
            index={i}
            arrayLength={arr.length}
            onDelete={handleDelete}
            onChange={handleValueChange}
            onPlusClick={handleClick}
          />
        </li>
      ))}
    </>
  );
};

export default Items;
