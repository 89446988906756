import React from "react";

import "./socialmedias.css";

//icons
import facebook_icon from "../../Images/SVG/icons/facebook_icon.svg";
import linkedin_icon from "../../Images/SVG/icons/linkedin_icon.svg";
import messenger_icon from "../../Images/SVG/icons/messenger_icon.svg";
import pinterest_icon from "../../Images/SVG/icons/pinterest_icon.svg";
import sms_icon from "../../Images/SVG/icons/sms_icon.svg";
import twitter_icon from "../../Images/SVG/icons/twitter_icon.svg";
import whatsapp_icon from "../../Images/SVG/icons/whatsapp_icon.svg";

function SocialMedias() {
  const urlPath = window.location.pathname + window.location.hash + window.location.search;
  const urlPathURI = encodeURI(urlPath);
  const urlPathURIComponent = encodeURIComponent(urlPath);

  const isScreenOnly = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false;
  };

  const isIOS = () => {
    return (
      ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };

  const sendFacebook = () => {
    window.open(
      "https://www.facebook.com/sharer.php?t=Dude%20Where%27s%20My%20Money%3F%20|%20Split%20Check%20Calculator&u=https%3A%2F%2Fdudewheresmy.money" +
        urlPathURIComponent,
      "Facebook",
      "height=600,width=400"
    );
  };

  const sendTwitter = () => {
    window.open(
      "https://twitter.com/intent/tweet?url=https%3A%2F%2Fdudewheresmy.money" +
        urlPathURIComponent +
        "&text=Dude%20Where%27s%20My%20Money%3F%20%7C%20Split%20Check%20Calculator",
      "Twitter",
      "height=600,width=400"
    );
  };

  const sendMessenger = () => {
    if (isScreenOnly()) {
      window.open(
        "fb-messenger://share?link=https%3A%2F%2Fdudewheresmy.money" + urlPathURIComponent,
        "Messenger",
        "height=600,width=400"
      );
    } else {
      window.open(
        "https://www.facebook.com/dialog/send?link=https%3A%2F%2Fdudewheresmy.money" +
          urlPathURIComponent +
          "&app_id=264461041665962&redirect_uri=https%3A%2F%2Fdudewheresmy.money" +
          urlPathURIComponent,
        "Facebook",
        "height=600,width=400"
      );
    }
  };

  const sendSMS = () => {
    if (isScreenOnly()) {
      let encodedURL = encodeURIComponent(window.location.href);
      if (isIOS()) {
        window.open("sms:&body=" + encodedURL);
      } else {
        window.open("sms:?body=" + encodedURL);
      }
    } else {
      alert("Sorry, sending via SMS is only available on mobile devices.");
    }
  };

  const sendWhatsApp = () => {
    window.open(
      "https://wa.me/?text=https%3A%2F%2Fdudewheresmy.money" + urlPathURIComponent,
      "WhatsApp",
      "height=600,width=400"
    );
  };

  const sendLinkedIn = () => {
    window.open(
      "https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fdudewheresmy.money" + urlPathURIComponent,
      "Linked In",
      "height=600,width=400"
    );
  };

  const sendPinterest = () => {
    const urlImage = encodeURIComponent("https://dudewheresmy.money/Images/preview_image.png");
    const urlDescription = encodeURIComponent(
      "DudeWheresMy.Money calculator helps you split a check between friends and/or family."
    );
    window.open(
      `http://pinterest.com/pin/create/button/?url=https%3A%2F%2Fdudewheresmy.money${urlPathURIComponent}&media=${urlImage}&description=${urlDescription}`,
      "Pinterest",
      "height=600,width=400"
    );
  };

  return (
    <div className="sharingButtons">
      <img
        alt="Facebook Social Media Icon"
        onClick={sendFacebook}
        src={facebook_icon}
        style={{ backgroundColor: "#3b5998" }}
      ></img>
      <img
        alt="Twitter Social Media Icon"
        onClick={sendTwitter}
        src={twitter_icon}
        style={{ backgroundColor: "#1DA1F2" }}
      ></img>
      <img
        alt="Messenger Social Media Icon"
        onClick={sendMessenger}
        src={messenger_icon}
        style={{ backgroundColor: "#006AFF" }}
      ></img>
      <img alt="SMS Texting Icon" onClick={sendSMS} src={sms_icon} style={{ backgroundColor: "#25D366" }}></img>
      <img
        alt="WhatsApp Social Media Icon"
        onClick={sendWhatsApp}
        src={whatsapp_icon}
        style={{ backgroundColor: "#43CC47" }}
      ></img>
      <img
        alt="LinkedIn Social Media Icon"
        onClick={sendLinkedIn}
        src={linkedin_icon}
        style={{ backgroundColor: "#2867b2" }}
      ></img>
      <img
        alt="Pinterest Social Media Icon"
        onClick={sendPinterest}
        src={pinterest_icon}
        style={{ backgroundColor: "#E60023" }}
      ></img>
    </div>
  );
}

export default SocialMedias;
