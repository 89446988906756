import React from "react";
import addImage from "../Images/Add.svg";
import delImage from "../Images/Delete.svg";

const Item = ({ value, index, arrayLength, onDelete, onChange: onItemChange, onPlusClick }) => {
  const handleItemChange = (event) => {
    onItemChange(index, event.target.value);
  };

  const prefix = () => {
    const num = index + 1;
    const theirPrefix = "Their ";
    if (num === 1) {
      return theirPrefix + "1st ";
    } else if (num === 2) {
      return theirPrefix + "2nd ";
    } else if (num === 3) {
      return theirPrefix + "3rd ";
    } else {
      return theirPrefix + num + "th ";
    }
  };

  return (
    <>
      <label htmlFor={"index" + index.toString()} className="label">
        {prefix() + "Item:"}
      </label>
      <span className="dollarSign">$</span>
      <input
        className="input"
        id={"index" + index.toString()}
        type="text"
        value={value}
        onChange={handleItemChange}
      ></input>
      <div className="addDelContainer">
        {index === 0 && arrayLength === 1 ? null : (
          <img className="removeButton" src={delImage} onClick={() => onDelete(index)}></img>
        )}
        {arrayLength - 1 === index ? (
          <img className="addButton" src={addImage} onClick={onPlusClick}></img>
        ) : // <button className="addButton" onClick={onPlusClick}></button>
        null}
      </div>
    </>
  );
};

export default Item;
