import React, { useState, useEffect, useRef } from "react";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";

import Items from "./Items";
import SocialMedias from "../SocialMedias/SocialMedias";
import "./sharable.css";
import logo from "../../Images/Logo.svg";
import bottomReceipt from "../../Images/Bottom Receipt.svg";

const Receipt = ({ match }) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const inputLink = useRef(null);
  const copiedConfirmation = useRef(null);
  const menu = useRef(null);
  const menuArrow = useRef(null);
  const sideDrop = useRef(null);
  let thisLink = "https://dudewheresmy.money/#/receipt/" + match.params.values;
  thisLink = typeof match.params.name === "undefined" ? thisLink : thisLink + "/" + match.params.name;
  thisLink = encodeURI(thisLink) + "/";
  let thisAllItems = [];
  let thisSubtotal = 0;
  let thisTaxTotal = 0;
  let thisFinalTotal = 0;
  let thisTaxPercentage = 0;
  let thisTax = 0;
  let thisTotal = 0;

  //Updating Url to React GA (google analytics)
  useEffect(() => {
    //Report Current Page
    ReactGA.initialize("UA-179123410-1");
    ReactGA.pageview(window.location.pathname + window.location.hash + window.location.search);
    //TODO: Names arent working for google analytics
  }, []);

  const initiateReceipt = () => {
    try {
      let array = match.params.values.split("ft");
      let finalTotal = array[1];
      finalTotal = parseFloat(finalTotal.split("d").join("."));
      array = array[0];
      array = array.split("tt");
      let totalTax = array[1];
      totalTax = parseFloat(totalTax.split("d").join("."));
      array = array[0];
      let allItems = array.split("i");
      allItems.shift();
      let allItemsAsNumber = [];
      let subtotal = 0;
      allItems.map((item) => {
        let number = parseFloat(item.split("d").join("."));
        allItemsAsNumber.push(number.toFixed(2));
        subtotal += number;
      });
      thisAllItems = allItemsAsNumber;
      thisSubtotal = subtotal.toFixed(2);
      thisTaxTotal = totalTax;
      thisFinalTotal = finalTotal;
      thisTaxPercentage = calculateTaxPercentage(thisTaxTotal, thisFinalTotal);
      thisTax = calculateTax(thisSubtotal, thisTaxPercentage);
      thisTotal = calculateTotal(thisSubtotal, thisTax);
      if (isNaN(thisTaxPercentage) || isNaN(thisTax) || isNaN(thisTotal)) throw 0;
    } catch (error) {
      thisAllItems = [0];
      thisSubtotal = thisTaxTotal = thisFinalTotal = thisTaxPercentage = thisTax = thisTotal = 0;
    }
  };

  const calculateTaxPercentage = (tax, total) => {
    total = Number(total) - tax;
    if (isNaN(total) || total === 0) {
      // setTaxPercentage(0); I Think this is causing error
      return 0;
    }
    let calculatedTaxPercentage = Math.round((tax / total) * 100000) / 1000;
    return calculatedTaxPercentage;
  };

  const calculateTax = (subTotal, taxPercentage) => {
    const decimal = parseFloat((taxPercentage / 100).toFixed(5));
    subTotal = parseFloat(subTotal);
    const roundedToCountingNumber = Math.round(subTotal * decimal * 100);
    const roundedToDecimal = roundedToCountingNumber / 100;
    return roundedToDecimal;
  };

  const calculateTotal = (subtotalAmount, taxAmount) => {
    return (parseFloat(subtotalAmount) + parseFloat(taxAmount)).toFixed(2);
  };

  const calculateName = () => {
    let tempName = typeof match.params.name === "undefined" ? "Them" : match.params.name;
    tempName = tempName.split("-").join(" ");
    return tempName;
  };

  const copyLink = (e) => {
    e.preventDefault();
    copiedConfirmation.current.style.animation = "none";
    inputLink.current.select();
    document.execCommand("copy");
    inputLink.current.blur();
    copiedConfirmation.current.style.animation = "animateCopied 2s";
  };

  const getCurrentTime = () => {
    const date = new Date();
    let hours = date.getHours();
    const suffix = hours < 12 ? "AM" : "PM";
    hours = suffix === "AM" ? hours : hours - 12;
    hours = hours === 0 ? 12 : hours;
    let minutes = date.getMinutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let seconds = date.getSeconds();
    seconds = seconds < 10 ? "0" + seconds : seconds;
    const displayTime = `${hours}:${minutes}:${seconds} ${suffix}`;
    return displayTime;
  };

  const getCurrentDate = () => {
    const date = new Date();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const todaysDate = `${month}/${day}/${year}`;
    return todaysDate;
  };

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setInterval(() => {
        if (!unmounted) {
          const newTime = getCurrentTime();
          setCurrentTime(newTime);
        }
      }, 1000);
    }
    return () => {
      unmounted = true;
    };
  }, []);

  const menuClicked = () => {
    if (!menuIsOpen) {
      menuArrow.current.style.cssText = "transform: rotate(0deg);";
      sideDrop.current.style.cssText = "transform: translateX(0px);";
      setMenuIsOpen(true);
    } else {
      menuArrow.current.style.cssText = "transform: rotate(-90deg);";
      sideDrop.current.style.cssText = "transform: translateX(200px);";
      setMenuIsOpen(false);
    }
  };
  return (
    <>
      <div className="navbar">
        <div ref={menu} className="navbarMenu" onClick={menuClicked}>
          <a>Menu</a>
          <span ref={menuArrow}></span>
        </div>
      </div>
      <div ref={sideDrop} className="sideDrop">
        <Link to="/">
          <p>New Receipt</p>
        </Link>
        <Link
          to={{
            pathname: "/",
            state: {
              toTutorial: true,
            },
          }}
        >
          <p>How Do I Use This?</p>
        </Link>
        <div className="emailUs">
          <p>
            If you have any suggestions or questions please feel free to email us at <br />
            <a className="emailUs_anchor" href="mailto:contact@dudewheresmy.money">
              contact@dudewheresmy.money
            </a>
          </p>
        </div>
      </div>
      <div className="container">
        {initiateReceipt()}
        <Link to="/" className="logo">
          <img src={logo} alt="Logo" width="100%"></img>
        </Link>
        <div className="content">
          {calculateName() !== "Them" ? (
            <p className="shareableSentYouMessage">
              {calculateName()}
              &nbsp;
              <span className="unbold">sent you this receipt.</span>
            </p>
          ) : null}
          <div className="copyLinkDiv">
            <input ref={inputLink} placeholder="Link of This Receipt" value={thisLink} type="text" readOnly />
            <span>
              <a onClick={(e) => copyLink(e)}>Copy Link To Share</a>
              <div ref={copiedConfirmation} className="copiedConfirmation">
                <span className="copiedConfirmationTriangle"></span>
                <p className="copiedConfirmationText">Link Copied</p>
              </div>
            </span>
          </div>
          <span className="or_span_text">Or share with</span>
          <SocialMedias />
          <div className={calculateName() === "Them" ? "topReceiptContainer autoRow" : "topReceiptContainer autoRow"}>
            <p className="topReceiptAddress">
              Address: www.dudewheresmy.money
              <br />
              Split Check Calculator
            </p>
            <p className="topReceiptDate">Date: {getCurrentDate()}</p>
            <p className="topReceiptTime">Time: {currentTime}</p>
            <p className="topReceiptDesc">Desc</p>
            <div className="topReceiptInputHeadingContainer">
              <p className="topReceiptFont amtLabel">AMT</p>
            </div>
          </div>
          <Items allItems={thisAllItems} />

          <div className="shareableSubtotalContainer">
            <p className="shareableSubtotalLabel">Subtotal:</p>
            <p className="shareableSubtotalDollarSign">${thisSubtotal}</p>
          </div>
          <div className="shareableTotalTaxedContainer">
            <p className="shareableTotalTaxedLabel">Tax:</p>
            <p className="shareableTotalTaxedDollarSign">${thisTax}</p>
          </div>
          <div className="shareableFinalTotalContainer">
            <p className="shareableFinalTotalLabel">Final Total:</p>
            <p className="shareableFinalTotalDollarSign">${thisTotal}</p>
          </div>

          <p className="calculatedOutput">
            Calculated Tax Percentage: &nbsp;
            <span className="calculatedOutputHighlightedText">{thisTaxPercentage}%</span>
          </p>
          <p className="calculatedOutput">
            Dude, You Owe&nbsp;
            <span className={calculateName() === "Them" ? "themText" : "shareableName"}>{calculateName()}</span>: &nbsp;
            <span className="calculatedOutputHighlightedText">${thisTotal}</span>
          </p>
          <div className="shareReceiptLink">
            <p>
              <Link to="/">Create A New Receipt</Link>
            </p>
          </div>
          <img src={bottomReceipt} className="bottomReceiptSVG"></img>
        </div>
      </div>
    </>
  );
};

export default Receipt;
