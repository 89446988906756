import React from "react";
import Scroll from "react-scroll";

import "../../styles.css";
import "./articles.css";
import priceInputImage from "../../Images/Tutorial/PriceInputs.png";
import totalTaxImage from "../../Images/Tutorial/TotalTaxInput.png";
import finalTotalImage from "../../Images/Tutorial/FinalTotalInput.png";
import enterNameImage from "../../Images/Tutorial/EnterName.png";
import finalResultImage from "../../Images/Tutorial/FinalResult.png";

//@TODO Get Tutorial To Work

function Tutorial() {
  const ScrollLink = Scroll.Link;
  return (
    <>
      <div className="articleContainer">
        <div className="article_content">
          <h1>How to use Dude Where's My Money</h1>
          <h2>First, Why use this application?</h2>
          <p>
            Dude, you know when you went out to eat with friends and/or family, and everything was charged on your
            card💳 (maybe cash💸)? Splitting the check and figuring out the math is a pain.
            <br />
            <br />
            <span>
              That is what this app is for! It helps you calculate how much people owe you when everything is paid by
              you. <br />
            </span>
            (Not just food by the way &#128514;)
          </p>
          <div className="article_content_steps">
            <h2>How to use this app</h2>
            <p>1. Grab your receipt with everyones order.</p>
            <p>
              2. Enter the price of their items.
              <br />
              (Use the "X" to delete a slot, and "+" to add a slot)
            </p>
            <img src={priceInputImage} alt="Step 2 Example" />
            <p>3. Enter the total amount you were taxed.</p>
            <img src={totalTaxImage} alt="Step 3 Example" />
            <p>4. Enter the final total on the receipt.</p>
            <img src={finalTotalImage} alt="Step 4 Example" />
            <p>
              5. View the results and add your name/nickname to share with the person that owes you.
              <br />
              (Emojis are allowed 😉)
            </p>
            <img src={enterNameImage} alt="Step 5 Example" />
            <p>6. Copy the link and share the receipt!😊</p>
            <img src={finalResultImage} alt="Step 6 Example" />
          </div>
          <p className="article_calltoaction_message">🎉Its That Easy! Go Make One Now!🎉</p>
          <ScrollLink className="article_calltoaction_button" to="top" spy={true} smooth={true} duration={1000}>
            Create Your Own Receipt
          </ScrollLink>
        </div>
      </div>
    </>
  );
}

export default Tutorial;
