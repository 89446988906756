import React, { useEffect } from "react";
import { HashRouter, Route, Switch, useHistory } from "react-router-dom";
// import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

import "./App.css";
import Calculator from "./Application/Calculator";
import Receipt from "./Application/SharableReceipt/Receipt";
import Tutorial from "./Application/Articles/Tutorial";

function App() {
  useEffect(() => {
    // const tagManagerArgs = {
    //   gtmId: "GTM-THPSR4N",
    // };
    ReactGA.initialize("UA-179123410-1");
    // TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <HashRouter>
      <Switch>
        <Route path="/receipt/:values/:name?" component={Receipt} />
        <Route path="/" component={Calculator} />
      </Switch>
    </HashRouter>
  );
}

export default App;
