import React, { useState, useEffect, useRef } from "react";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import Scroll, { Element } from "react-scroll";

// import "./App.css";

import Items from "./Items";
import Tutorial from "./Articles/Tutorial";
import "../styles.css";
import logo from "../Images/Logo.svg";
import bottomReceipt from "../Images/Bottom Receipt.svg";
import arrow_down from "../Images/SVG/arrow_down.svg";

import SocialMedias from "./SocialMedias/SocialMedias";

export const Event = (category, action, label) => {
  ReactGA.event({ category: category, action: action, label: label });
};

const Calculator = ({ location }) => {
  const [itemsTotal, setItemsTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [totalTheyOwe, setTotalTheyOwe] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [values, setValues] = useState(0);
  const [name, setName] = useState("");

  const toTutorial = location.state === undefined ? false : true;
  const tutorial = useRef(null);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const menu = useRef(null);
  const menuArrow = useRef(null);
  const sideDrop = useRef(null);
  const ScrollLink = Scroll.Link;

  useEffect(() => {
    calculateTotalTheyOwe();
  });

  //Updating Url to React GA (google analytics)
  useEffect(() => {
    //Report Current Page
    ReactGA.initialize("UA-179123410-1");
    ReactGA.pageview(window.location.pathname + window.location.hash + window.location.search);
  }, []);

  //Jump To Tutorial if set to True
  useEffect(() => {
    //If toTutorial = true, then start at tutorial
    if (toTutorial) {
      window.scrollTo(0, tutorial.current.offsetTop);
    }
  }, []);

  const handleChange = (event, type) => {
    let newTax = tax;
    let newTotal = total;
    if (type === "Tax") {
      setTax(event.target.value);
      newTax = event.target.value;
    }
    if (type === "Total") {
      setTotal(event.target.value);
      newTotal = event.target.value;
    }
    calculateTaxPercentage(newTax, newTotal);
  };

  const calculateTaxPercentage = (newTax, newTotal) => {
    newTotal = Number(newTotal) - newTax;
    if (isNaN(newTotal) || newTotal === 0) {
      setTaxPercentage(0);
      return;
    }
    let calculatedTaxPercentage = Math.round((newTax / newTotal) * 100000) / 1000;
    setTaxPercentage(calculatedTaxPercentage);
  };

  const handleItemSum = (totalSum) => {
    setItemsTotal(totalSum);
    calculateTotalTheyOwe(totalSum);
  };

  const calculateTotalTheyOwe = (totalSum = itemsTotal) => {
    const decimal = 1 + taxPercentage / 100;
    setTotalTheyOwe((totalSum * decimal).toFixed(2));
  };

  const getCurrentTime = () => {
    const date = new Date();
    let hours = date.getHours();
    const suffix = hours < 12 ? "AM" : "PM";
    hours = suffix === "AM" ? hours : hours - 12;
    hours = hours === 0 ? 12 : hours;
    let minutes = date.getMinutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let seconds = date.getSeconds();
    seconds = seconds < 10 ? "0" + seconds : seconds;
    const displayTime = `${hours}:${minutes}:${seconds} ${suffix}`;
    return displayTime;
  };

  const getCurrentDate = () => {
    const date = new Date();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const todaysDate = `${month}/${day}/${year}`;
    return todaysDate;
  };

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setInterval(() => {
        if (!unmounted) {
          const newTime = getCurrentTime();
          setCurrentTime(newTime);
        }
      }, 1000);
    }
    return () => {
      unmounted = true;
    };
  }, []);

  //Functions below for Link Creating
  const handleArrayOfItems = (values) => {
    setValues(values);
  };
  const createLink = (e) => {
    e.preventDefault();

    Event("Share Receipt", "Click", "Homepage"); //For event trigger in analytics

    //i = item, d = decimal, tt= total taxed, ft= final total
    let shareLink = "";
    let thisValues = values === 0 ? ["0"] : values;
    thisValues.map((item) => {
      let number = item.toString();
      number = number.split(".");
      number = number.join("d");
      let currentItemString = "i" + number;
      shareLink += currentItemString;
    });
    shareLink += "tt" + tax.toString().split(".").join("d");
    shareLink += "ft" + total.toString().split(".").join("d");

    let linkName = name !== "" ? name.split(" ").join("-") : name;
    shareLink = linkName === "" ? shareLink : shareLink + "/" + linkName.toString();
    shareLink = "https://dudewheresmy.money/#/receipt/" + encodeURI(shareLink) + "/";
    window.open(shareLink, "_blank");
  };

  const menuClicked = () => {
    if (!menuIsOpen) {
      menuArrow.current.style.cssText = "transform: rotate(0deg);";
      sideDrop.current.style.cssText = "transform: translateX(0px);";
      setMenuIsOpen(true);
    } else {
      menuArrow.current.style.cssText = "transform: rotate(-90deg);";
      sideDrop.current.style.cssText = "transform: translateX(200px);";
      setMenuIsOpen(false);
    }
  };

  return (
    <>
      <div className="navbar">
        <div ref={menu} className="navbarMenu" onClick={menuClicked}>
          <a>Menu</a>
          <span ref={menuArrow}></span>
        </div>
      </div>
      <div ref={sideDrop} className="sideDrop">
        <ScrollLink to="top" spy={true} smooth={true} duration={1000} onClick={menuClicked}>
          <p>New Receipt</p>
        </ScrollLink>
        <ScrollLink to="tutorial" spy={true} smooth={true} duration={1000} offset={-50} onClick={menuClicked}>
          <p>How Do I Use This?</p>
        </ScrollLink>
        <div className="emailUs">
          <p>
            If you have any suggestions or questions please feel free to email us at <br />
            <a className="emailUs_anchor" href="mailto:contact@dudewheresmy.money">
              contact@dudewheresmy.money
            </a>
          </p>
        </div>
      </div>
      <div id="top" className="container">
        <Link to="/" className="logo">
          <img src={logo} alt="Logo" width="100%"></img>
        </Link>
        <div className="content">
          <div className="share_this_website_div">
            <img className="arrow_down_left" src={arrow_down}></img>
            <p className="share_website_p">Share This Website</p>
            <img className="arrow_down_right" src={arrow_down}></img>
          </div>
          <SocialMedias />
          <div className="topReceiptContainer autoRow">
            <p className="topReceiptAddress">
              Address: www.dudewheresmy.money
              <br />
              Split Check Calculator
            </p>
            <p className="topReceiptDate">Date: {getCurrentDate()}</p>
            <p className="topReceiptTime">Time: {currentTime}</p>
            <p className="topReceiptDesc">Their Items</p>
            <div className="topReceiptInputHeadingContainer">
              <p className="topReceiptFont amtLabel">AMT</p>
              <p className="topReceiptFont delAddLabel">DEL | ADD</p>
            </div>
          </div>
          <Items onItemValueChanged={handleItemSum} sendArrayOfItems={handleArrayOfItems} />

          <div className="totalTaxedContainer">
            <p className="totalTaxedLabel">Total Tax (On Your Receipt):</p>
            <p className="totalTaxedDollarSign">$</p>
            <input
              className="totalTaxedInput"
              type="text"
              value={tax}
              onChange={(e) => {
                handleChange(e, "Tax");
              }}
            ></input>
          </div>

          <div className="finalTotalContainer">
            <p className="finalTotalLabel">Final Total (On Your Receipt):</p>
            <p className="finalTotalDollarSign">$</p>
            <input
              className="finalTotalInput"
              type="text"
              value={total}
              onChange={(e) => {
                handleChange(e, "Total");
              }}
            ></input>
          </div>

          <p className="calculatedOutput">
            Calculated Tax Percentage: &nbsp;
            <span className="calculatedOutputHighlightedText">{taxPercentage}%</span>
          </p>
          <p className="calculatedOutput">
            Dude, They Owe You: &nbsp;
            <span className="calculatedOutputHighlightedText">${totalTheyOwe}</span>
          </p>
          <div className="shareReceiptLink">
            <p>
              <input placeholder="Your Name (Optional)" type="text" onChange={(e) => setName(e.target.value)} />
              <a id="Share-Receipt-Action" onClick={createLink} href="https://dudewheresmy.money/#/receipt/">
                Share This Receipt
              </a>
            </p>
          </div>
          <img src={bottomReceipt} className="bottomReceiptSVG"></img>
        </div>
      </div>
      <div ref={tutorial}>
        <Element id="tutorial" name="tutorial">
          <Tutorial></Tutorial>
        </Element>
      </div>
    </>
  );
};

export default Calculator;
